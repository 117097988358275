import * as React from 'react'
import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { isEmptyString } from '@digital-magic/ts-common-utils'
import { UserId } from '@api/endpoints'
import { getUser, User } from '@api/endpoints/users'
import { useDefaultQueryErrorHandlerPublic } from '@hooks/ReactQuery'
import { queryKeyGetUserByUserId } from './queryKeys'

export const useQueryGetUser = (userId: UserId | undefined): UseQueryResult<User> => {
  const errorHandler = useDefaultQueryErrorHandlerPublic()

  const query = useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: queryKeyGetUserByUserId(userId ?? -1),
    queryFn: () => getUser(userId ?? -1),
    enabled: !isEmptyString(userId)
  })

  React.useEffect(() => {
    if (query.error) {
      errorHandler(query.error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.error])

  return query
}
