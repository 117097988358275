import * as React from 'react'
import { UseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query'
import { CreateUserSchema, postUserCreate } from '@api/endpoints/users'
import { QueryErrorHandler, useDefaultQueryErrorHandlerPublic, UseMutationProps } from '@hooks/ReactQuery'
import { queryKeyGetUsersAll } from './queryKeys'

export const useMutationCreateUser = ({
  onSuccess
}: UseMutationProps): UseMutationResult<void, unknown, CreateUserSchema> => {
  const queryClient = useQueryClient()
  const defaultErrorHandler = useDefaultQueryErrorHandlerPublic()
  const mutation = useMutation({
    mutationFn: postUserCreate
  })

  const errorHandler: QueryErrorHandler = (e) => {
    defaultErrorHandler(e)
  }

  React.useEffect(() => {
    if (mutation.error) {
      errorHandler(mutation.error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutation.error])

  React.useEffect(() => {
    if (mutation.isSuccess) {
      void queryClient.invalidateQueries({ queryKey: queryKeyGetUsersAll })
      void onSuccess?.()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutation.isSuccess])

  return mutation
}
