import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { hasValue, isEmpty, parseOptionalIntNanSafe } from '@digital-magic/ts-common-utils'
import { privateRoutes } from '@constants/routes'
import { UserId } from '@api/endpoints'
import { UserRoleEnumSchema } from '@api/endpoints/auth'
import { CreateUserSchema } from '@api/endpoints/users'
import { Button } from '@mui/material'
import { useEnumTranslation } from '@hooks/Translation/EnumTranslation'
import { useQueryGetUser } from '@hooks/ReactQuery/users/useQueryGetUser'
import { useMutationCreateUser } from '@hooks/ReactQuery/users/useMutationCreateUser'
import { useMutationDeleteUser } from '@hooks/ReactQuery/users/useMutationDeleteUser'
import { FormTextField, useZodForm } from '@controls/Form'
import { ButtonWithConfirmation } from '@controls/buttons'
import { buildEnumOptions, renderMenuItems } from '@controls/utils'
import { AdminPage } from '@layout/AdminPage'
import { FlexContainer } from '@layout/FlexContainer'

type Params = Readonly<{
  userId: string
}>

export const UserPage: React.FC = () => {
  const params = useParams<Params>()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { roleTranslation } = useEnumTranslation()

  const onCreateUserSuccess = (): void => {
    navigate(privateRoutes.Users)
  }

  // TODO: Sometimes navigation moves away after getUser is re-fetched due to queryKey invalidation (maybe we have to disable query on deletion?)
  const onDeleteUserSuccess = (): void => {
    navigate(privateRoutes.Users)
  }

  const createUser = useMutationCreateUser({ onSuccess: onCreateUserSuccess })
  const deleteUser = useMutationDeleteUser({ onSuccess: onDeleteUserSuccess })

  const userId = parseOptionalIntNanSafe(params.userId)
  const getUser = useQueryGetUser(userId)

  const form = useZodForm({ schema: CreateUserSchema })

  const roleOptions = React.useMemo(() => buildEnumOptions(UserRoleEnumSchema, roleTranslation), [roleTranslation])

  const onDeleteUserClick =
    (userId: UserId) =>
    (confirmResult: boolean): void => {
      if (confirmResult) {
        deleteUser.mutate(userId)
      }
    }

  const onRefreshClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault()
    void getUser.refetch()
  }

  React.useEffect(() => {
    if (getUser.data) {
      form.reset(getUser.data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUser.data])

  return (
    <AdminPage>
      <h1>{userId ? t('pages.user.edit.title') : t('pages.user.new.title')}</h1>
      {/* TODO: Remove console output - it is for debug purposes */}
      <form onSubmit={form.handleSubmit((data) => createUser.mutate(data))}>
        <FlexContainer vertical>
          <FormTextField
            control={form.control}
            name="userName"
            label={t('pages.user.form.fields.username')}
            disabled={hasValue(userId)}
          />
          {isEmpty(userId) && (
            <FormTextField
              control={form.control}
              name="password"
              label={t('pages.user.form.fields.password')}
              type="password"
            />
          )}
          {isEmpty(userId) && (
            <FormTextField
              control={form.control}
              name="password2"
              label={t('pages.user.form.fields.password2')}
              type="password"
            />
          )}
          <FormTextField control={form.control} name="role" select label={t('pages.user.form.fields.role')}>
            {renderMenuItems(roleOptions)}
          </FormTextField>
          <div>
            {isEmpty(userId) && (
              <Button variant="contained" type="submit">
                {t('global.buttons.submit')}
              </Button>
            )}
            {hasValue(userId) && (
              <>
                <Button variant="contained" onClick={onRefreshClick}>
                  {t('global.buttons.refresh')}
                </Button>
                <ButtonWithConfirmation
                  color="error"
                  onConfirmResult={onDeleteUserClick(userId)}
                  confirmTitle={t('global.consents.delete.title')}
                  confirmMessage={t('global.consents.delete.message')}
                >
                  {t('global.buttons.delete')}
                </ButtonWithConfirmation>
              </>
            )}
          </div>
        </FlexContainer>
      </form>
    </AdminPage>
  )
}
